import { Component, OnInit, ViewChild } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { holidayService } from './holiday.service';
import { AppConfigService } from '../../app.config';
import { holiday } from '../../models/lookups/holiday.model';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from 'ngx-bootstrap';




@Component({
  selector: 'crm-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css']
})
export class holidayComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  holidays: holiday[] = [];
  holidayId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  selectedHolidayId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  public logo = require("../../../app/assets/images/demo/Icon_Holiday.png");

  constructor(private alertService: AlertService,
    private holidayService: holidayService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchholidays();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }
  new() {
    this.addNew = true;
    //this.router.navigate(['add-holiday']);
  }
  searchholidays() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.holidayService.Search(this.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;

      this.holidays = result.items;
      this.totalItems = result.totalItems;
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

        this.alertService.showStickyMessage("Load Error", `Unable to retrieve holidays from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }

  pageChanged(event) {
    this.currentPage = event.first / event.rows + 1;
  /*  this.currentPage = event.page;*/
    this.searchholidays();
  }

  gotoEdit(holidayId) {
    this.selectedHolidayId = holidayId;
    this.addNew = true;
    //this.router.navigate(['add-holiday', holidayId]);
  }

  confirmDelete(holidayId) {
    this.selectedHolidayId = holidayId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteholiday(holidayId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  deleteholiday() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.holidayService.Delete(this.selectedHolidayId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
      this.searchholidays();
    }, () => {
        this.displayMode = DisplayModes.Success;
        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
      });
  }

  closeAdding(event) {
    this.selectedHolidayId = undefined;
    this.addNew = false;
    if (event) {
      this.searchholidays();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}

