import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Branch } from '../branc.model';
import { DisplayModes } from '../../../models/enums';
import { BranchesService } from '../branches.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';

@Component({
  selector: 'crm-save-branch',
  templateUrl: './save-branch.component.html',
  styleUrls: ['./save-branch.component.css']
})
export class SaveBranchComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  branch: Branch = new Branch();
  //itemFormatId: number;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() branchId: number;
  constructor(private readonly branchesService: BranchesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.branchId != undefined)
      this.getBranch(this.branchId);
  }

  getBranch(branchId) {
    this.displayMode = DisplayModes.Loading;
    this.branchesService.getById(branchId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.branch = responce as Branch;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item Branch');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.branchesService.save(this.branch).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }


}
