import { Component, OnInit, ViewChild } from '@angular/core';
import { Permission } from '../../models/permission.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { DisplayModes } from '../../models/enums';
import { Branch } from './branc.model';
import { ModalDirective } from 'ngx-bootstrap';
import { BranchesService } from './branches.service';
import { Router } from '@angular/router';
import { AppTranslationService } from '../../services/app-translation.service';
import { AppConfigService } from '../../app.config';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'crm-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})
export class BranchesComponent implements OnInit {

  branches: Branch[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedBranchId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly branchesService: BranchesService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getBranches();
  }

  getBranches() {
    this.displayMode = DisplayModes.Loading;
    this.branchesService.search(this.searchText).subscribe(responce => {
      this.branches = responce as Branch[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item branches');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
    //this.router.navigate(['add-item-format']);
  }

  edit(id) {
    this.selectedBranchId = id;
    this.addNew = true;
    //this.router.navigate(['add-item-format/' + id]);
  }

  confirmDelete(brancg) {
    this.selectedBranchId = brancg;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("clm.DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(itemFormat),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("clm.OperationCancelled"), "", MessageSeverity.Default));
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.branchesService.delete(this.selectedBranchId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.branches.indexOf(this.selectedBranchId);
      this.branches.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete branche:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageBranches() {
    return this.accountService.userHasPermission(Permission.manageBranchesPermission);
  }

  closeAdding(event) {
    debugger;
    this.selectedBranchId = undefined;
    this.addNew = false;
    if (event) {
      this.getBranches();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
