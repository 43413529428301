import { Component, OnInit, ViewChild } from '@angular/core';
import { ItemRange } from '../../models/lookups/item-range.model';
import { DisplayModes } from '../../models/enums';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AppConfigService } from '../../app.config';
import { ItemRangeService } from './item-range.service';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';

@Component({
  selector: 'crm-item-range',
  templateUrl: './item-range.component.html',
  styleUrls: ['./item-range.component.css']
})
export class ItemRangeComponent implements OnInit {

  itemRanges: ItemRange[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedItemRangeId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly itemRangeService: ItemRangeService,
    private accountService: AccountService) { }


  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getItemRanges();
  }

  getItemRanges() {
    this.displayMode = DisplayModes.Loading;
    this.itemRangeService.search(this.searchText).subscribe(responce => {
      this.itemRanges = responce as ItemRange[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item ranges');
        console.log(err);
      })
  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedItemRangeId = id;
    this.addNew = true;
  }

  confirmDelete(itemRange) {
    this.selectedItemRangeId = itemRange;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemRangeService.delete(this.selectedItemRangeId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.itemRanges.indexOf(this.selectedItemRangeId);
      this.itemRanges.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete item fromat:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageItemRanges() {
    return this.accountService.userHasPermission(Permission.manageItemRangesPermission);
  }

  closeAdding(event) {
    this.selectedItemRangeId = undefined;
    this.addNew = false;
    if (event) {
      this.getItemRanges();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
