import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AccountService } from '../../../services/account.service';
import { Permission } from '../../../models/permission.model';
import { SettingService } from '../../../settings/components/settings/setting.service';
import { Setting } from '../../../settings/models/setting.model';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '../../../../shared/app-consts';
import { environment } from '../../../../environments/environment';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'crm-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  public profilePic = require("../../../assets/images/demo/avatars/1.jpg");
  public hcpClassLogo = require("../../../assets/images/demo/HCP Class.png");

  public orgTypeLogo = require("../../../assets/images/demo/Icon_Organization.png");
  public usersLogo = require("../../../assets/images/demo/Icon_Users Managements.png");
  public rolesLogo = require("../../../assets/images/demo/Icon_Roles Management.png");
  public specialitiesLogo = require("../../../assets/images/demo/Icon_Specialities.png");
  public buLogo = require("../../../assets/images/demo/Icon_Business Units.png");
  public buTypeLogo = require("../../../assets/images/demo/Icon_Business Units Types.png");
  public jobsLogo = require("../../../assets/images/demo/Icon_Jobs.png");
  public brickLevelLogo = require("../../../assets/images/demo/Icon_Brick Levels.png");
  public bricksLogo = require("../../../assets/images/demo/Icon_Bricks.png");
  public itemsLogo = require("../../../assets/images/demo/Icon_Items.png");
  public itemFormatLogo = require("../../../assets/images/demo/Icon_Items Format.png");
  public itemBrandLogo = require("../../../assets/images/demo/Icon_Items Format.png");
  public giveawayItemsLogo = require("../../../assets/images/demo/Icon_Giveaway Items.png");
  public representationLogo = require("../../../assets/images/demo/Icon_Representations.png");
  public orgJobStrLogo = require("../../../assets/images/demo/Icon_Organization Job Structure.png");
  public sysSettingLogo = require("../../../assets/images/demo/Icon_System Settings.png");
  public expenseTypeLogo = require("../../../assets/images/demo/Icon_Expenses Types.png");
  public workingDayLogo = require("../../../assets/images/demo/Icon_Working Days.png");
  public holidayLogo = require("../../../assets/images/demo/Icon_Holiday.png");
  public nonFieldtypeLogo = require("../../../assets/images/demo/Icon_Non Field Activities Type.png");
  public vacationTypeLogo = require("../../../assets/images/demo/Icon_Vacations Type.png");
  public salesFileLogo = require("../../../assets/images/demo/Icon_Sales.png");
  public dashBoardLogo = require("../../../assets/images/demo/Dashboard.png");
  public generalSettingLogo = require("../../../assets/images/demo/Group 15712.png");
  public profileSettingLogo = require("../../../assets/images/demo/Profile Settings.png");
  public hcpLogo = require("../../../assets/images/demo/Group 15697.png");
  public hcoLogo = require("../../../assets/images/demo/HCO.png");
  public cyclesLogo = require("../../../assets/images/demo/Group 15696.png");
  public planLogo = require("../../../assets/images/demo/Plan.png");
  public userHcoReqLogo = require("../../../assets/images/demo/User HCO Requests.png");
  public usrSamplesReqLogo = require("../../../assets/images/demo/User Samples Requests.png");
  public visitsLogo = require("../../../assets/images/demo/Visits.png");
  public externalActivityTypesLogo = require("../../../assets/images/demo/Group 15704.png");
  public activitiesLogo = require("../../../assets/images/demo/Activities.png");
  public expensesLogo = require("../../../assets/images/demo/Expenses.png");
  public coashingLogo = require("../../../assets/images/demo/Group 15719.png");
  public vacCreditLogo = require("../../../assets/images/demo/Group 15706.png");
  public contentsLogo = require("../../../assets/images/demo/Group 15707.png");
  public consentsLogo = require("../../../assets/images/demo/Group 15718.png");
  public consentTypeLogo = require("../../../assets/images/demo/Path 264530.png");
  public consentFormLogo = require("../../../assets/images/demo/Group 15723.png");
  public emailTemplateLogo = require("../../../assets/images/demo/Group 15715.png");
  public emailCampaignLogo = require("../../../assets/images/demo/Group 15717.png");
  public reportsLogo = require("../../../assets/images/demo/Reports.png");
  public nonFieldActivityLogo = require("../../../assets/images/demo/Non Field Activities.png");

  private logo = "";//require("../../../assets/images/pixel-admin/main-navbar-logo.png");
  settings: Setting[] = [];
  applyingCLM: string;
  applyingSalesFile: string;
  applyingSampleModule: string;
  applyingVacationModule: string;
  applyingPharmaciesModule: string;
  applyingIncentivesModule: string;
  salesUrl: string;
  constructor(private authService: AuthService,
    private accountService: AccountService,
    private configurations: ConfigurationService,
    private http: HttpClient,
    private settingService: SettingService) {

  }

  ngOnInit() {
    //this.accountService.getLogo(1).subscribe(response => {
    //  this.logo = response as string;
    //}, err => {
    //});

    //this.accountService.getLogo(2).subscribe(response => {
    //  this.profilePic = response as string;
    //}, err => {
    //});
    const appBaseUrl = this.getDocumentOrigin() + AppConsts.appBaseHref;
    this.http
      .get<any>(`${appBaseUrl}assets/${environment.appConfig}`, {})
      .subscribe((response) => {
        console.log(response);
        this.salesUrl = response.salesUrl;
        this.settingService.getAllSettingsByURL(response.apiUrl).subscribe(result => {
          this.settings = result as Setting[];
          this.applyingCLM = this.settings.find(x => { return x.key == "applyingCLM"; }).value;
          this.applyingSalesFile = this.settings.find(x => { return x.key == "applyingSalesFile"; }).value;
          this.applyingSampleModule = this.settings.find(x => { return x.key == "applyingSampleModule"; }).value;
          this.applyingVacationModule = this.settings.find(x => { return x.key == "applyingVacationModule"; }).value;
          this.applyingPharmaciesModule = this.settings.find(x => { return x.key == "applyingPharmaciesModule"; }).value;
          this.applyingIncentivesModule = this.settings.find(x => { return x.key == "applyingIncentivesModule"; }).value;

        }, error => { console.log(error) });

      });

    if (this.authService.currentUser && this.authService.currentUser.profilePicFileId) {
      if (this.authService.currentUser && this.authService.currentUser.profilePicFileId) {
        let interval = setInterval(() => {
          if (this.configurations.apiAddress) {
            this.profilePic = this.configurations.apiAddress + '/' + this.authService.currentUser.profilePicFileId;
            clearInterval(interval);
          }
        }, 5000);
      }
    }

  }

  private getDocumentOrigin(): string {
    if (!document.location.origin) {
      const port = document.location.port ? ':' + document.location.port : '';
      return (
        document.location.protocol + '//' + document.location.hostname + port
      );
    }

    return document.location.origin;
  }

  gotoSales() {
    window.open(this.salesUrl, "_blank");
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }

  get canViewItems() {
    return this.accountService.userHasPermission(Permission.viewItemsPermission);
  }

  get canViewOrganizationTypes() {
    return this.accountService.userHasPermission(Permission.viewOrganizationTypesPermission);
  }

  get canViewBricks() {
    return this.accountService.userHasPermission(Permission.viewBricksPermission);
  }

  get canViewBusinessUnits() {
    return this.accountService.userHasPermission(Permission.viewBusinessUnitsPermission);
  }

  get canViewJobs() {
    return this.accountService.userHasPermission(Permission.viewJobsPermission);
  }

  get canViewItemTypes() {
    return this.accountService.userHasPermission(Permission.viewItemTypesPermission);

  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canViewHcos() {
    return this.accountService.userHasPermission(Permission.viewHcosPermission);
  }

  get canManageHcos() {
    return this.accountService.userHasPermission(Permission.manageHcosPermission);
  }
  get canAddHcoRequest() {
    return this.accountService.userHasPermission(Permission.addHcoRequestPermission);
  }
  get canReviewPlans() {
    return this.accountService.userHasPermission(Permission.reviewPlansPermission);
  }

  get canViewHcps() {
    return this.accountService.userHasPermission(Permission.viewHcpsPermission);
  }

  get canManageHcps() {
    return this.accountService.userHasPermission(Permission.manageHcpsPermission);
  }

  get canViewCycles() {
    return this.accountService.userHasPermission(Permission.viewCyclesPermission);
  }

  get canManageCycles() {
    return this.accountService.userHasPermission(Permission.manageCyclesPermission);
  }

  get canViewOrganizationJobStructures() {
    return this.accountService.userHasPermission(Permission.viewOrganizationJobStructuresPermission);
  }
  get canViewSpecialities() {
    return this.accountService.userHasPermission(Permission.viewSpecialitiesPermission);
  }

  get canViewBrickLevels() {
    return this.accountService.userHasPermission(Permission.viewBrickLevelsPermission);
  }

  get canViewUserHCOsRequests() {
    return this.accountService.userHasPermission(Permission.viewUserHCOsRequestsPermission);
  }

  get canViewPlans() {
    return this.accountService.userHasPermission(Permission.viewPlansPermission);
  }

  get canManagePlans() {
    return this.accountService.userHasPermission(Permission.createPlanPermission);
  }

  get canTrackPlans() {
    return this.accountService.userHasPermission(Permission.trackPlansPermission);
  }

  get canViewExpenses() {
    return this.accountService.userHasPermission(Permission.viewExpensesPermission);
  }

  get canCreateExpensesPermission() {
    return this.accountService.userHasPermission(Permission.createExpensesPermission);
  }

  get canReviewExpensesPermission() {
    return this.accountService.userHasPermission(Permission.reviewExpensesPermission);
  }



  get canViewAllReports() {
    return this.accountService.userHasPermission(Permission.viewAllReportsPermission);
  }

  get canViewActualVisitsReport() {
    return this.accountService.userHasPermission(Permission.viewActualVisitsPermission);
  }
  get canViewTotalVisitsReport() {
    return this.accountService.userHasPermission(Permission.viewTotalVisitsPermission);
  }
  get canViewCoverageReport() {
    return this.accountService.userHasPermission(Permission.viewCoverageReportPermission);
  }
  get canViewWorkingDays() {
    return this.accountService.userHasPermission(Permission.viewWorkingDaysPermission);

  }
  get canViewHolidays() {
    return this.accountService.userHasPermission(Permission.viewHolidayPermission);

  }

  get canCreateVacations() {
    return this.accountService.userHasPermission(Permission.manageVacationPermission);

  }
  get canViewVacations() {
    return this.accountService.userHasPermission(Permission.viewVacationPermission);

  }
  get canReviewVacations() {
    return this.accountService.userHasPermission(Permission.reviewVacationPermission);

  }
  get canViewVacationTypes() {
    return this.accountService.userHasPermission(Permission.viewVacationTypePermission);

  }

  get canViewCoachings() {
    return this.accountService.userHasPermission(Permission.viewCoachingPermission);

  }
  get canViewTopics() {
    return this.accountService.userHasPermission(Permission.viewTopicPermission);

  }
  get canViewQuestionHeads() {
    return this.accountService.userHasPermission(Permission.viewQuestionHeadPermission);

  }
  get canViewQuestions() {
    return this.accountService.userHasPermission(Permission.viewQuestionPermission);

  }

  get canViewCoachingTransactions() {
    return this.accountService.userHasPermission(Permission.viewCoachingTransactionsPermission);

  }

  // -------------------------------
  get canViewBusinessUnitTypes() {
    return this.accountService.userHasPermission(Permission.viewBusinessUnitTypesPermission);

  }

  get canViewHcpClass() {
    return this.accountService.userHasPermission(Permission.viewHCPClassesPermission);

  }

  get canViewExpenseTypes() {
    return this.accountService.userHasPermission(Permission.viewExpenseTypePermission);

  }

  get canViewActivityTypes() {
    return this.accountService.userHasPermission(Permission.viewActivityTypesPermission);

  }

  get canViewVisit() {
    return this.accountService.userHasPermission(Permission.viewVisitPermission);

  }

  get canCreateVisit() {
    return this.accountService.userHasPermission(Permission.manageVisitPermission);

  }

  get canViewUserHcoRequests() {
    return this.accountService.userHasPermission(Permission.viewUserHcoRequestPermission);
  }

  get canCreateUserHcoRequests() {
    return this.accountService.userHasPermission(Permission.createUserHcoRequestPermission);
  }

  get canManageSubordinatesUserHcoRequestsPermission() {
    return this.accountService.userHasPermission(Permission.manageSubordinatesUserHcoRequestsPermission);

  }

  get canTrackUserHcoRequests() {
    return this.accountService.userHasPermission(Permission.trackUserHcoRequestsPermission);
  }


  get canViewUserHCOs() {
    return this.accountService.userHasPermission(Permission.viewUserHCOsPermission);

  }

  get canViewHCOsLineClass() {
    return this.accountService.userHasPermission(Permission.viewHCOsLineClassPermission);
  }

  get canViewDoubleVisit() {
    return this.accountService.userHasPermission(Permission.viewDoubleVisitPermission);
  }

  get canViewRepresentations() {
    return this.accountService.userHasPermission(Permission.ViewRepresentationsPermission);
  }

  get canViewPlanReport() {
    return this.accountService.userHasPermission(Permission.ViewPlansReportPermission);
  }

  get canViewBusinessUnitStructure() {
    return this.accountService.userHasPermission(Permission.ViewBusinessUnitStructurePermission);
  }

  get canViewContents() {
    return this.accountService.userHasPermission(Permission.viewContents);
  }

  get canManageContents() {
    return this.accountService.userHasPermission(Permission.manageContents);
  }

  get canViewConsents() {
    return this.accountService.userHasPermission(Permission.viewConsents);
  }

  get canManageConsents() {
    return this.accountService.userHasPermission(Permission.manageConsents);
  }

  get canViewConsentTypes() {
    return this.accountService.userHasPermission(Permission.viewConsentTypes);
  }

  get canManageConsentTypes() {
    return this.accountService.userHasPermission(Permission.manageConsentTypes);
  }

  get canViewConsentForms() {
    return this.accountService.userHasPermission(Permission.viewConsentForms);
  }

  get canAddConsentForms() {
    return this.accountService.userHasPermission(Permission.addConsentForms);
  }

  get canEditConsentForms() {
    return this.accountService.userHasPermission(Permission.editConsentForms);
  }

  get canViewEmailTemplates() {
    return this.accountService.userHasPermission(Permission.viewEmailTemplates);
  }

  get canManageEmailTemplates() {
    return this.accountService.userHasPermission(Permission.manageEmailTemplates);
  }

  get canManageEmailTransactions() {
    return this.accountService.userHasPermission(Permission.manageEmailTransactions);
  }

  get canViewEmailTransactions() {
    return this.accountService.userHasPermission(Permission.viewEmailTransactions);
  }

  get canViewSpecialityClassReport() {
    return this.accountService.userHasPermission(Permission.viewSpecialityClassReportPermission);
  }
  get canViewProudctSpecialityReport() {
    return this.accountService.userHasPermission(Permission.viewProudctSpecialityReportPermission);
  }

  get canViewProudctSpecialityClassReport() {
    return this.accountService.userHasPermission(Permission.viewProudctSpecialityClassReportPermission);
  }

  get canViewProudctOrganizationTypeReport() {
    return this.accountService.userHasPermission(Permission.viewProudctOrganizationTypeReportPermission);
  }

  get canViewVacationReport() {
    return this.accountService.userHasPermission(Permission.viewVacationReportPermission);
  }

  get canViewNonFieldActivityReport() {
    return this.accountService.userHasPermission(Permission.viewNonFieldActivityReportPermission);
  }

  get canUploadSalesFile() {
    return this.accountService.userHasPermission(Permission.uploadSalesFile);
  }

  get canManageSettings() {
    return this.accountService.userHasPermission(Permission.manageSettings);
  }

  get canViewUserSampleRequests() {
    return this.accountService.userHasPermission(Permission.viewUserSampleRequests);
  }

  get canCreateUserSampleRequests() {
    return this.accountService.userHasPermission(Permission.createUserSampleRequests);
  }

  get canManageAllUserSampleRequests() {
    return this.accountService.userHasPermission(Permission.manageAllUserSampleRequests);
  }

  get canManageSubUserSampleRequests() {
    return this.accountService.userHasPermission(Permission.manageSubUserSampleRequests);
  }

  get canViewSampleStockReport() {
    return this.accountService.userHasPermission(Permission.viewSampleStockReportPermission);
  }
  get canViewSampleTrackerReport() {
    return this.accountService.userHasPermission(Permission.viewSampleTrackerReportPermission);
  }
  get canViewGiveawayTrackerReportPermission() {
    return this.accountService.userHasPermission(Permission.viewGiveawayTrackerReportPermission);
  } v
  get canViewAbsentReport() {
    return this.accountService.userHasPermission(Permission.viewAbsentReportPermission);
  }

  get canViewCoashingDashboardPermission() {
    return this.accountService.userHasPermission(Permission.viewCoashingDashboardPermission);
  }

  get canViewHcoReactionReportPermission() {
    return this.accountService.userHasPermission(Permission.viewHcoReactionReportPermission);
  }

  get canViewVacationCreditsPermission() {
    return this.accountService.userHasPermission(Permission.viewVacationCredits);
  }

  get canManageVacationCreditsPermission() {
    return this.accountService.userHasPermission(Permission.manageVacationCredits);
  }

  get canManageSubordinateVacationCreditsPermission() {
    return this.accountService.userHasPermission(Permission.manageSubordinateVacationCredits);
  }

  get canProvisionVacationCreditsPermission() {
    return this.accountService.userHasPermission(Permission.provisionVacationCredits);
  }

  get canViewVacationCredeitReportPermission() {
    return this.accountService.userHasPermission(Permission.viewVacationCredeitReportPermission);
  }

  get canViewPlannedDaysReportPermission() {
    return this.accountService.userHasPermission(Permission.viewPlannedDaysReportPermission);
  }

  get canViewActivityReportPermission() {
    return this.accountService.userHasPermission(Permission.viewActivityReportPermission);
  }

  get canViewItemFormats() {
    return this.accountService.userHasPermission(Permission.viewItemFormatsPermission);
  }

  get canViewBranches() {
    return this.accountService.userHasPermission(Permission.viewBranchesPermission);
  }

  get canManagItemFormats() {
    return this.accountService.userHasPermission(Permission.manageItemFormatsPermission);
  }

  get canViewItemBrands() {
    return this.accountService.userHasPermission(Permission.viewItemBrandsPermission);
  }

  get canViewItemRanges() {
    return this.accountService.userHasPermission(Permission.viewItemRangesPermission);
  }

  get canManagItemBrands() {
    return this.accountService.userHasPermission(Permission.manageItemBrandsPermission);
  }


  get canViewAvgContentSlideTimeReportPermission() {
    return this.accountService.userHasPermission(Permission.viewAvgContentSlideTimeReportPermission);
  }

  get canViewAvgProductWithContentReportPermission() {
    return this.accountService.userHasPermission(Permission.viewAvgProductWithContentReportPermission);
  }

  get canViewDetailingTimeContentReportPermission() {
    return this.accountService.userHasPermission(Permission.viewDetailingTimeContentReportPermission);
  }

  get canViewFackedVisitLocationReportPermission() {
    return this.accountService.userHasPermission(Permission.viewFackedVisitLocationReportPermission);
  }

  get canViewSyncLogErrorsReportPermission() {
    return this.accountService.userHasPermission(Permission.viewSyncLogErrorsReportPermission);
  }

  get canViewUpToDateStatusReportPermission() {
    return this.accountService.userHasPermission(Permission.viewUpToDateStatusReportPermission);
  }

  get canViewReaction() {
    return this.accountService.userHasPermission(Permission.viewReactionPermission);
  }

  get canManagReaction() {
    return this.accountService.userHasPermission(Permission.manageReactionPermission);
  }

  get canViewVisitScriptReportPermission() {
    return this.accountService.userHasPermission(Permission.viewVisitScriptReportPermission);
  }

  get canViewActivityPermission() {
    return this.accountService.userHasPermission(Permission.viewActivityPermission);
  }
  get canAddActivityPermission() {
    return this.accountService.userHasPermission(Permission.addActivityPermission);
  }
  get canEditActivityPermission() {
    return this.accountService.userHasPermission(Permission.editActivityPermission);
  }
  get canDeleteActivityPermission() {
    return this.accountService.userHasPermission(Permission.deleteActivityPermission);
  }
  get canReviewActivityPermission() {
    return this.accountService.userHasPermission(Permission.reviewActivityPermission);
  }
  get canViewDeletedActivitiesPermission() {
    return this.accountService.userHasPermission(Permission.viewDeletedActivitiesPermission);
  }
  get canViewDistributorsPermission() {
    return this.accountService.userHasPermission(Permission.viewDistributorsPermission);
  }

  get canViewBrickStructureReportPermission() {
    return this.accountService.userHasPermission(Permission.viewBrickStructureReportPermission);
  }

  get canViewMessagesPermission() {
    return this.accountService.userHasPermission(Permission.viewMessagesPermission);
  }

  get canCreateMessagesPermission() {
    return this.accountService.userHasPermission(Permission.createMessagesPermission);
  }

  get canViewManagerPerformanceReportPermission() {
    return this.accountService.userHasPermission(Permission.viewManagerPerformanceReportPermission);
  }

  get canViewProductiveCallFreqReportPermission() {
    return this.accountService.userHasPermission(Permission.viewProductiveCallFreqReportPermission);
  }

  get canViewProductiveCallFreqPharmacyReportPermission() {
    return this.accountService.userHasPermission(Permission.viewProductiveCallFreqPharmacyReportPermission);
  }


  get canViewHcoOrgTypeReportPermission() {
    return this.accountService.userHasPermission(Permission.viewHcoOrgTypeReportPermission);

  }

  get canViewTotalDoubleVisitsReport() {
    return this.accountService.userHasPermission(Permission.viewTotalDoubleVisitsPermission);
  }

  get canViewCoachingRateReportPermission() {
    return this.accountService.userHasPermission(Permission.viewCoachingRateReportPermission);
  }

  get canViewCoachingDetailsReportPermission() {
    return this.accountService.userHasPermission(Permission.viewCoachingDetailsReportPermission);
  }

  get canViewPharmaciesPermission() {
    return this.accountService.userHasPermission(Permission.viewPharmaciesPermission);
  }
  get canReviewPharmaciesPermission() {
    return this.accountService.userHasPermission(Permission.reviewPharmaciesPermission);
  }

  get canViewUserPharmacyRequestsPermission() {
    return this.accountService.userHasPermission(Permission.viewUserPharmacyRequestsPermission);
  }

  get canReviewUserPharmacyRequestsPermission() {
    return this.accountService.userHasPermission(Permission.reviewUserPharmacyRequestsPermission);
  }

  get canViewUserPharmacyHcoLinkPermission() {
    return this.accountService.userHasPermission(Permission.viewUserPharmacyHcoLinkPermission);
  }

  get canViewPharmacyPlansPermission() {
    return this.accountService.userHasPermission(Permission.viewPharmacyPlansPermission);
  }

  get canViewPharmacyVisitPermission() {
    return this.accountService.userHasPermission(Permission.viewPharmacyVisitPermission);
  }


  get canViewFeedbackPermission() {
    return this.accountService.userHasPermission(Permission.viewFeedbackPermission);
  }
  get canManageIncentive() {
    return this.accountService.userHasPermission(Permission.manageIncentive);
  }
  get canCreateIncentive() {
    return this.accountService.userHasPermission(Permission.createIncentive);
  }
  get canEditIncentive() {
    return this.accountService.userHasPermission(Permission.editIncentive);
  }

  get canViewActivityGroupPermission() {
    return this.accountService.userHasPermission(Permission.viewActivityGroupPermission);
  }
  get canViewActivityCostCenterPermission() {
    return this.accountService.userHasPermission(Permission.viewActivityCostCenterPermission);
  }

  get canViewActivityCostTypePermission() {
    return this.accountService.userHasPermission(Permission.viewActivityCostTypePermission);
  }

  get canViewVendorPermission() {
    return this.accountService.userHasPermission(Permission.viewVendorPermission);
  }

  get canViewNonSalesKpisReportPermission() {
    return this.accountService.userHasPermission(Permission.viewNonSalesKpisReportPermission);
  }

  get canViewReturnOnInvestmentReportPermission() {
    return this.accountService.userHasPermission(Permission.viewReturnOnInvestmentReportPermission);
  }

  get canViewBusinessUnitBricksHistoryReportPermission() {
    return this.accountService.userHasPermission(Permission.viewBusinessUnitBricksHistoryReportPermission);
  }

  get canViewPhVisitPerformanceReportPermission() {
    return this.accountService.userHasPermission(Permission.viewPhVisitPerformanceReportPermission);
  }

  get canViewHcoLinkedPharmaciesReportPermission() {
    return this.accountService.userHasPermission(Permission.viewHcoLinkedPharmaciesReportPermission);
  }

  get canViewLinkedPharmacyStatisticsReportPermission() {
    return this.accountService.userHasPermission(Permission.viewLinkedPharmacyStatisticsReportPermission);
  }

  get canViewUserBricksPermission() {
    return this.accountService.userHasPermission(Permission.viewUserBricksPermission);
  }

  get canViewMergedHcosReport() {
    return this.accountService.userHasPermission(Permission.viewMergedHcosReportPermission);
  }
}
