<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!branchId">Create {{'Branch' | translate}}</span>
    <span class="panel-title" *ngIf="branchId">Edit {{'Branch' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-12">
            <div [ngClass]="{'has-success': (branchName.dirty || branchName.touched || f.submitted)  && branchName.valid, 'has-error' : (branchName.dirty || branchName.touched || f.submitted)  && branchName.invalid}">
              <label class="control-label">{{'clm.name' | translate}} </label>
              <input class="form-control" placeholder="{{'clm.name' | translate}}" required
                     name="branchName" #branchName="ngModel"
                     [(ngModel)]="branch.name" type="text">
              <div *ngIf="(branchName.dirty || branchName.touched || f.submitted)  && branchName.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" [disabled]="f.invalid">{{'Save' | translate}}</button>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




