import { Injectable, Injector } from "@angular/core";
import { EndpointFactory } from "../../../services/endpoint-factory.service";
import { HttpHeaders, HttpClient, HttpRequest, HttpParams } from "@angular/common/http";
import { AppTranslationService } from "../../../services/app-translation.service";
import { ConfigurationService } from "../../../services/configuration.service";
import { LocalStoreManager } from "../../../services/local-store-manager.service";
import { DBkeys } from "../../../services/db-Keys";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class HcoService extends EndpointFactory {

  //headers = new HttpHeaders();
  culture: string = "ar";

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) {
    super(http, configurations, injector);

    //this.headers = new HttpHeaders({
    //  'Authorization': "Bearer " + this.localStorage.getData(DBkeys.ACCESS_TOKEN),
    //  'Content-Type': "application/json",
    //  'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
    //  'App-Version': ConfigurationService.appVersion,
    //  'Access-Control-Allow-Origin': "*",
    //  'Access-Control-Allow-Methods': "GET,POST,PATCH,PUT,DELETE,OPTIONS",
    //  'Access-Control-Allow-Headers': "Origin, Content-Type, X-Auth-Token"
    //});
    this.culture = this.configurations.language;
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getAll");
  }

  getAllHCPs() {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getAllHCPs");
  }

  downloadCsvTemplate(): Observable<Blob> {
    //return this.http.get(this.configurations.apiAddress + '/api/hco/downloadCSVTemplate');
    //let options = new RequestOptions({ responseType: ResponseContentType.Blob });
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadCSVTemplate", { responseType: "blob" });

  }

  downloadErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadErrorFile", { responseType: "blob" });
  }

  uploadCsvTemplate(formData) {
    //const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/hco/uploadCSVTemplate`, formData, {
    //  reportProgress: true,
    //});
    //return this.http.request(uploadReq);
    return this.http.post(this.configurations.apiAddress + "/api/hco/uploadCSVTemplate", formData);

  }

  updateFromCSV(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/hco/updateFromCSV", formData);
  }

  addHcoHcps(hcoId: number, hcpIds: number[]) {
    let myParams = new HttpParams()
      .set("hcoId", hcoId.toString())
      .set("hcpIds", hcpIds.join(', '))
    return this.http.get(this.configurations.apiAddress + "/api/hco/addHcoHcps", { params: myParams });
  }

  getHcoHcpsByHcoId(hcoId: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getHcoHcpsByHcoId/" + hcoId);
  }

  addHcpHcos(hcpId: number, hcoIds: number[]) {
    let myParams = new HttpParams()
      .set("hcpId", hcpId.toString())
      .set("hcoIds", hcoIds.join(', '))
    return this.http.get(this.configurations.apiAddress + "/api/hco/addHcpHcos", { params: myParams });
  }

  getHcpHcosByHcpId(hcpId: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getHcpHcosByHcpId/" + hcpId);
  }



  searchUserHcos(searchText?: string, userId?: number, getLastVisitNote: boolean = false, buId = 0) {

    let myParams = new HttpParams()
      .set("searchText", searchText === undefined ? "" : searchText)
      .set("getLastVisitNote", getLastVisitNote.toString())
      .set("userId", userId === undefined ? "" : userId.toString())
      .set("buId", buId.toString());


    return this.http.get(`${this.configurations.apiAddress}/api/hco/userHcos`, { params: myParams });
  }
  allUserHcos(searchText?: string, userId?: number, pageSize?: number, pageNumber?: number, buId = 0) {

    let myParams = new HttpParams()
      .set("searchText", searchText === undefined ? "" : searchText)
      .set("pageSize", pageSize === undefined ? "900000" : pageSize.toString())
      .set("pageNumber", pageNumber === undefined ? "1" : pageNumber.toString())
      .set("buId", buId.toString())
      .set("userId", userId === undefined ? "" : userId.toString());
    return this.http.get(`${this.configurations.apiAddress}/api/hco/allUserHcos`, { params: myParams });
  }
  alluserHcosTotalCount(searchText?: string, userId?: number, buId=0) {

    let myParams = new HttpParams()
      .set("searchText", searchText === undefined ? "" : searchText)
      .set("buId", buId.toString())
      .set("userId", userId === undefined ? "" : userId.toString());
    return this.http.get(`${this.configurations.apiAddress}/api/hco/alluserHcosTotalCount`, { params: myParams });
  }

  getPendingApproval(searchText: string, brickId: number, organizationTypeId: number, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set("brickId", brickId == undefined ? "0" : brickId.toString())
      .set("organizationTypeId", organizationTypeId == undefined ? "0" : organizationTypeId.toString())
      .set("searchText", searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString());
    //.set("pageSize", pageSize == undefined?"": pageSize.toString());
    return this.http.get(this.configurations.apiAddress + "/api/hco/pendingApproval", { params: myParams });
  }
  filterUserHcos(organizationTypeId: number, brickId: number) {
    let myParams = new HttpParams()
      .set("organizationTypeId", organizationTypeId.toString())
      .set("brickId", brickId.toString());

    return this.http.get(`${this.configurations.apiAddress}/api/hco/filterUserHcos`, { params: myParams });
  }
  deleteHcoHcpsById(id: number) {
    return this.http.delete(this.configurations.apiAddress + "/api/hco/deleteHcoHcpsById/" + id);
  }

  getAllHcosTree() {
    return this.http.get(`${this.configurations.apiAddress}/api/hco/getAllHcosTree`);
  }

  getAllHcosTreeByOrganizationType(organizationTypeId) {
    return this.http.get(`${this.configurations.apiAddress}/api/hco/GetAllHcosTreeByOrganizationType/` + organizationTypeId);
  }

  getHcoDetails(hcoId) {
    return this.http.get(`${this.configurations.apiAddress}/api/hco/getById/${hcoId}`);
  }

  //HCO BusinessUnit Classes
  getHCOBusinessUnitClasses(hcoId) {
    return this.http.get(`${this.configurations.apiAddress}/api/hcoBusinessUnitClass/getAll/${hcoId}`);
  }
  saveHCOBusinessUnitClass(hcoBusinessUnitClass) {
    return this.http.post(`${this.configurations.apiAddress}/api/hcoBusinessUnitClass/save`, hcoBusinessUnitClass);
  }

  deleteHCOBusinessUnitClass(hcoId, businessUnitId) {
    return this.http.delete(`${this.configurations.apiAddress}/api/hcoBusinessUnitClass/delete/${hcoId}/${businessUnitId}`);
  }

  GetAllOrganizationType() {
    return this.http.get(`${this.configurations.apiAddress}/api/OrganizationType/getAll`);
  }

  getHcoChilds(parentId) {
    return this.http.get(`${this.configurations.apiAddress}/api/hco/getHcoChilds/${parentId}`);
  }


  downloadHcpHcosCSVTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadHcpHcosCSVTemplate", { responseType: "blob" });

  }

  downloadHcpHcosErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadHcpHcosErrorFile", { responseType: "blob" });
  }

  provisionHcpHcosFromCSV(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/hco/provisionHcpHcosFromCSV", formData);

  }

  downloadMergeHcosIntoHcoCSVTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadMergeHcosIntoHcoCSVTemplate", { responseType: "blob" });
  }

  downloadMergeHcosIntoHcoErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/hco/downloadMergeHcosIntoHcoErrorFile", { responseType: "blob" });
  }

  provisionMergeHcosIntoHco(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/hco/provisionMergeHcosIntoHco", formData);
  }

}
