<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==1">
  <div class="container text-center">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
  </div>

</div>
<form #f="ngForm" *ngIf="displayMode==2"
      novalidate (ngSubmit)="save(f)">
  <div class="col-sm-12">

    <div class="form-group no-margin-hr row">

      <div class="col-sm-6">
        <label class="control-label">Serach</label>
        <input type="text"
               name="name"
               [(ngModel)]="searchText"
               placeholder="{{'Type Here' |translate}}"
               #name="ngModel"
               autocomplete="off"
               class="form-control" />
      </div>
      <div class="col-sm-6">
        <label class="control-label">{{'Specialities' | translate}} </label>
        <span class="ui-fluid">
          <p-dropdown [options]="specialitys"
                      placeholder="{{'Speciality' | translate}}"
                      emptyFilterMessage="No results found"
                      showClear="true"
                      styleClass="wid100"
                      name="specialityId"
                      #specialityId="ngModel"
                      [(ngModel)]="search.speciality"
                      [filter]="true" optionLabel="name">
          </p-dropdown>
        </span>
      </div>
      <div class="col-sm-6">
        <label class="control-label">{{'settings.header.BrickName' | translate}} </label>
        <span class="ui-fluid">
          <p-dropdown [options]="bricks"
                      placeholder="Select Brick"
                      #brickId="ngModel"
                      emptyFilterMessage="No results found"
                      showClear="true"
                      styleClass="wid100"
                      name="brickId"
                      [(ngModel)]="search.brick"
                      [filter]="true" optionLabel="name">


          </p-dropdown>
        </span>
      </div>
      <div class="col-sm-6">
        <label class="control-label">{{'settings.header.OrganizationType' | translate}} </label>
        <span class="ui-fluid">
          <p-dropdown [options]="organizationTypes"
                      placeholder="{{'Organization Type' | translate}}"
                      emptyFilterMessage="No results found"
                      showClear="true"
                      styleClass="wid100"
                      name="organizationTypeId"
                      #organizationTypeId="ngModel"
                      [(ngModel)]="search.organizationType"
                      [filter]="true" optionLabel="name">
          </p-dropdown>
        </span>
      </div>
      <div class="col-sm-12">
        <button type="button" class="btn btn-save" (click)="getHcos()">{{'Search' | translate}}</button>
      </div>

      <div class="col-sm-12">


        <p-table #dt [value]="hcos" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize" [resizableColumns]="true"
                 [totalRecords]="totalItems" [lazy]="true" (onLazyLoad)="pageChanged($event)">
          <ng-template pTemplate="header">
            <tr>
              <th *ngIf="!viewOnly" style="text-align:center" class="table-edit">Select</th>
              <th style="text-align:center" class="table-edit">Id</th>
              <th style="text-align:center" class="table-edit">Name</th>
              <th style="text-align:center" class="table-edit">Speciality</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-hco>
            <tr *ngIf="hcos.length>0 && displayMode==2">

              <td *ngIf="!viewOnly" style="height:40px"> <input type="checkbox" class="custom-control-input" [(ngModel)]="hco.selected" name="selected_{{hco.id}}" id="{{hco.id}}" (change)="onSelectHco(hco)"></td>
              <td style="height:40px"><label class="custom-control-label">{{hco.id}}</label></td>
              <td style="height:40px"><label class="custom-control-label" for="{{hco.id}}">{{hco.name}}</label></td>
              <td style="height:40px"><label class="custom-control-label">{{hco.specialityName}}</label></td>
            </tr>
            <tr *ngIf="hcos.length==0 && displayMode==2">
              <td colspan="5">{{'NoDataFound' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>


      <div class="panel-footer margin-left-20 margin-top-20">
        <button class="btn  btn-cancel" type="button" (click)="back()">{{'Back' | translate}}</button>&nbsp;
        <button *ngIf="!viewOnly" class="btn  btn-save" type="submit">{{'Save' | translate}}</button>
      </div>
    </div>
  </div>

</form>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




