import { AttachmentFile } from "../../expenses/models/attachement-file.model";

export class Vendor {
  id: number;
  name: string;
  comercialRecord: string;
  taxCard: string;
  bankAccountNo: string;
  bankName: string;
  branchNo: string;
  addedValueRegestrationNo: string;
  active: boolean;
  files: AttachmentFile[];
  endDate?: Date;
}
