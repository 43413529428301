<div id="main-menu" role="navigation">
  <div id="main-menu-inner">
    <div class="menu-content top" id="menu-content-demo">

      <div>
        <div class="text-bg">
          <span class="text-slim">welcome,</span>
          <br />
          <span class="text-semibold">{{userName}}</span>
        </div>

        <img [src]="profilePic" alt="" class="">
        <!--<div class="btn-group">
            <a href="#" class="btn btn-xs btn-danger btn-outline dark"><i class="fa fa-power-off"></i></a>
          </div>
          <a href="#" class="close">&times;</a>-->
      </div>
    </div>
    <ul class="navigation">
      <li class="mm-dropdown">
        <a href="#"><img [src]="dashBoardLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.dashboard' |translate}}</span></a>
        <ul>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/dashboard"><span class="mm-text">{{'mainMenu.dashboard' | translate}}</span></a>
          </li>

          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewCoashingDashboardPermission">
            <a routerLink="/dashboard/dashboard2"><span class="mm-text">{{'Dashboard Coaching' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="applyingSalesFile == 'True'">
            <a [routerLink]="" (click)="gotoSales()"><span class="mm-text">{{'Sales Dashboard' | translate}}</span></a>
          </li>
        </ul>
      </li>
      <li class="mm-dropdown" *ngIf="canViewItemTypes || canViewOrganizationTypes || canViewOrganizationTypes || canViewUsers || canViewRoles || canViewSpecialities || canViewBusinessUnits || canViewBusinessUnitTypes || canViewJobs || canViewBrickLevels || canViewBricks || canViewItems || canViewItemFormats || canViewItemBrands || canViewReaction || canViewOrganizationJobStructures || canViewUserHCOsRequests || canViewBusinessUnitTypes || canViewHcpClass || canViewExpenseTypes || canViewActivityTypes || canViewWorkingDays || canViewHolidays || canViewVacationTypes">
        <a href="#"><img [src]="generalSettingLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'General Settings' |translate}}</span></a>
        <ul>

          <li *ngIf="canViewOrganizationTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="organization-types"><img [src]="orgTypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.OrganizationTypes' | translate}}</span></a>
          </li>
          <li *ngIf="canViewUsers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="users-management"><img [src]="usersLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.UsersManagements' | translate}}</span></a>
          </li>
          <li *ngIf="canViewRoles" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="roles-management"><img [src]="rolesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.RolesManagement' | translate}}</span></a>
          </li>
          <li *ngIf="canViewSpecialities" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="specialities"><img [src]="specialitiesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Specialities' |translate}}</span></a>
          </li>
          <li *ngIf="canViewBusinessUnits" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="business-units"><img [src]="buLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Business Units' | translate}}</span></a>
          </li>
          <li *ngIf="canViewBusinessUnits" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="shared-bricks"><img [src]="buLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Shared Bricks' | translate}}</span></a>
          </li>

          <li *ngIf="canViewBusinessUnitTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="business-unit-types"><img [src]="buTypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Business Unit Types' | translate}}</span></a>
          </li>
          <li *ngIf="canViewJobs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="jobs"><img [src]="jobsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.Jobs' | translate}}</span></a>
          </li>
          <li *ngIf="canViewBrickLevels" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="settings/brick-levels"><img [src]="brickLevelLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.BrickLevels' | translate}}</span></a>
          </li>
          <li *ngIf="canViewBricks" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/bricks"><img [src]="bricksLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.Bricks' | translate}}</span></a>
          </li>
          <li *ngIf="canViewItems" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="items"><img [src]="itemsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.Items' | translate}}</span></a>
          </li>
          <li *ngIf="canViewItemFormats" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="item-formats"><img [src]="itemFormatLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Item Format' | translate}}</span></a>
          </li>

          <li *ngIf="canViewItemBrands" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="item-brands"><img [src]="itemBrandLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Item Brand' | translate}}</span></a>
          </li>
          <li *ngIf="canViewItemTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="item-types"><img [src]="itemBrandLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Item Category' | translate}}</span></a>
          </li>
          <li *ngIf="canViewItemRanges" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="item-ranges"><img [src]="itemBrandLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Item Range' | translate}}</span></a>
          </li>
          <li *ngIf="canViewReaction" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="reactions"><img [src]="itemFormatLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Reactions' | translate}}</span></a>
          </li>
          <li *ngIf="canViewItems" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="giveaway-items"><img [src]="giveawayItemsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Giveaway Items' | translate}}</span></a>
          </li>
          <li *ngIf="canViewRepresentations" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="representations"><img [src]="representationLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Representations' | translate}}</span></a>
          </li>
          <li *ngIf="canViewFeedbackPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="feedback"><img [src]="representationLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Feedback' | translate}}</span></a>
          </li>
          <li *ngIf="canViewBranches" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="branches"><img [src]="itemFormatLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Branches' | translate}}</span></a>
          </li>
          <li *ngIf="canViewOrganizationJobStructures" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="org-job-str"><img [src]="orgJobStrLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.OrganizationJobStructure' | translate}}</span></a>
          </li>
          <li *ngIf="canViewUserHCOsRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/hcos-request"><span class="mm-text">{{'settings.header.UserHCOsRequests' | translate}}</span></a>
          </li>
          <li *ngIf="canManageSettings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/setting"><img [src]="sysSettingLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'System Settings' | translate}}</span></a>
          </li>

          <li *ngIf="canViewExpenseTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="expenses/expense-types"><img [src]="expenseTypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.expensesTypes' | translate}}</span></a>
          </li>
          <li *ngIf="canViewWorkingDays" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="working-days"><img [src]="workingDayLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.workingdays' | translate}}</span></a>
          </li>
          <li *ngIf="applyingPharmaciesModule == 'True' && canViewWorkingDays" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="pharmacy-working-days"><img [src]="workingDayLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">Pharmacy {{'settings.header.workingdays' | translate}}</span></a>
          </li>
          <li *ngIf="canViewHolidays" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="holiday"><img [src]="holidayLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'settings.header.holiday' | translate}}</span></a>
          </li>
          <li *ngIf="canViewVacationTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="non-field-types"><img [src]="nonFieldtypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Non field activities type' | translate}}</span></a>
          </li>
          <li *ngIf="canViewVacationTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="vacation-types"><img [src]="vacationTypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Vacations type' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canUploadSalesFile && applyingSalesFile == 'True'">
            <a routerLink="sales-file"><img [src]="salesFileLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Sales File' | translate}}</span></a>
          </li>
          <li *ngIf="canViewDistributorsPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="distributor"><img [src]="salesFileLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Distributor' | translate}}</span></a>
          </li>
        </ul>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="settings"><img [src]="profileSettingLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Profile Settings' | translate}}</span></a>
      </li>
      <li *ngIf="canViewHcpClass" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="hcp-class"><img [src]="hcpClassLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.HCPClass' | translate}}</span></a>
      </li>
      <li *ngIf="canViewHcps" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="hcps"><img [src]="hcpLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'HCP' | translate}}</span></a>
      </li>
      <li class="mm-dropdown mm-dropdown-root" *ngIf="canViewHcos || canManageHcos">
        <a href="#"><img [src]="hcoLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'HCO' |translate}}</span></a>
        <ul>

          <!--<li *ngIf="canManageHcos || canAddHcoRequest" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/settings/add-hco"><span class="mm-text">{{'Add' | translate}}</span></a>
      </li>-->
          <li *ngIf="canViewHcos" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/hcos"><span class="mm-text">{{'Search' | translate}}</span></a>
          </li>
          <li *ngIf="canManageHcos" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/settings/hcos/review-add-requests"><span class="mm-text">{{'Review Add Requests' | translate}}</span></a>
          </li>

        </ul>
      </li>
      <li class="mm-dropdown mm-dropdown-root" *ngIf="applyingPharmaciesModule == 'True' && (canViewPharmaciesPermission || canViewUserPharmacyRequestsPermission)">
        <a href="#"><img [src]="hcoLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Pharmacy' |translate}}</span></a>
        <ul>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewPharmaciesPermission">
            <a routerLink="/settings/pharmacies"><span class="mm-text">{{'Search' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canReviewPharmaciesPermission">
            <a routerLink="/settings/pharmacies/review-add-requests"><span class="mm-text">{{'Review Add Requests' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewUserPharmacyRequestsPermission">
            <a routerLink="/user-pharmacy-requests"><span class="mm-text">{{'User Pharmacy Request' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canReviewUserPharmacyRequestsPermission">
            <a routerLink="/user-pharmacy-requests/pending-delete"><span class="mm-text">{{'Pending Delete User Pharmacy' | translate}}</span></a>
          </li>

          <li *ngIf="canViewPharmacyPlansPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/pharmacy-plans"><span class="mm-text">{{'Plans' | translate}}</span></a>
          </li>
          <li *ngIf="canViewPharmacyVisitPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/pharmacy-visits"><span class="mm-text">{{'Visits' | translate}}</span></a>
          </li>
        </ul>
      </li>
      <li *ngIf="canViewCycles" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="cycles"><img [src]="cyclesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Cycles' | translate}}</span></a>
      </li>

      <li *ngIf="canViewPlans" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/plans"><img [src]="planLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.plan' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewUserBricksPermission">
        <a routerLink="/user-bricks"><img [src]="userHcoReqLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">User Bricks</span></a>
      </li>
      <li class="mm-dropdown mm-dropdown-root" *ngIf="canViewUserHcoRequests || canCreateUserHcoRequests">
        <a href="#"><img [src]="userHcoReqLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.userHcoRequests' |translate}}</span></a>
        <ul>
          <!--<li *ngIf="canCreateUserHcoRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/user-hco-requests/add"><span class="mm-text">{{'userHcoRequests.add' | translate}}</span></a>
      </li>-->
          <li *ngIf="canViewUserHcoRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="user-hco-requests"><span class="mm-text">{{'userHcoRequests.search' | translate}}</span></a>
          </li>
          <!--<li *ngIf="canCreateUserHcoRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/user-hco-requests/my-list"><span class="mm-text">{{'userHcoRequests.myList' | translate}}</span></a>
      </li>-->
          <li *ngIf="canManageSubordinatesUserHcoRequestsPermission || canTrackUserHcoRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/user-hco-requests/pending-delete"><span class="mm-text">{{'Deleted Requests' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewUserPharmacyHcoLinkPermission">
            <a routerLink="/user-pharmacy-requests/linked-pharmacy"><span class="mm-text">{{'Linked Pharmacy' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewUserPharmacyHcoLinkPermission">
            <a routerLink="/user-pharmacy-requests/linked-pharmacy-requests/add"><span class="mm-text">{{'Linked Pharmacy Adding Requests' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewUserPharmacyHcoLinkPermission">
            <a routerLink="/user-pharmacy-requests/linked-pharmacy-requests/delete"><span class="mm-text">{{'Linked Pharmacy Deleting Requests' | translate}}</span></a>
          </li>
        </ul>
      </li>
      <li class="mm-dropdown mm-dropdown-root" *ngIf="(canViewUserSampleRequests || canCreateUserSampleRequests || canManageAllUserSampleRequests || canManageSubUserSampleRequests)  && applyingSampleModule == 'True'">
        <a href="#"><img [src]="usrSamplesReqLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'User Samples Requests' |translate}}</span></a>
        <ul>
          <!--<li *ngIf="canCreateUserSampleRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/user-sample-requests/add"><span class="mm-text">{{'Add' | translate}}</span></a>
      </li>-->
          <li *ngIf="canViewUserSampleRequests || canCreateUserSampleRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="user-sample-requests"><span class="mm-text">{{'Search' | translate}}</span></a>
          </li>
          <li *ngIf="canManageAllUserSampleRequests || canManageSubUserSampleRequests" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/user-sample-requests/review"><span class="mm-text">{{'Review' | translate}}</span></a>
          </li>

        </ul>
      </li>

      <li *ngIf="canViewVisit" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/visits"><img [src]="visitsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.visits' | translate}}</span></a>
      </li>
      <li *ngIf="canViewActivityTypes" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="activity-types"><img [src]="externalActivityTypesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'External Activity Types' | translate}}</span></a>
      </li>
      <li *ngIf="canViewActivityGroupPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="activity-group"><img [src]="externalActivityTypesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Activity Group' | translate}}</span></a>
      </li>
      <li *ngIf="canViewActivityCostCenterPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="activity-cost-center"><img [src]="externalActivityTypesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Activity Cost Center' | translate}}</span></a>
      </li>
      <li *ngIf="canViewActivityCostTypePermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="activity-cost-type"><img [src]="externalActivityTypesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Activity Cost Type' | translate}}</span></a>
      </li>

      <li *ngIf="canViewVendorPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="vendor"><img [src]="externalActivityTypesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Vendor' | translate}}</span></a>
      </li>

      <li class="mm-dropdown mm-dropdown-root" *ngIf="canViewActivityPermission || canReviewActivityPermission">
        <a href="#"><img [src]="activitiesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Activity' |translate}}</span></a>
        <ul>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewActivityPermission">
            <a routerLink="/rep-activities/out-side-activities"><span class="mm-text">{{'Activities' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canReviewActivityPermission">
            <a routerLink="/rep-activities/out-side-activities-review"><span class="mm-text">{{'Review' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canViewDeletedActivitiesPermission">
            <a routerLink="/rep-activities/out-side-activities/true"><span class="mm-text">{{'Deleted Activities' | translate}}</span></a>
          </li>

        </ul>
      </li>

      <li class="mm-dropdown mm-dropdown-root" *ngIf="canCreateExpensesPermission || canViewExpenses || canReviewExpensesPermission">
        <a href="#"><img [src]="expensesLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.expenses' |translate}}</span></a>
        <ul>

          <!--<li routerLinkActive="active" *ngIf="canCreateExpense" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/transactions/add"><span class="mm-text">{{'controls.add' | translate}}</span></a>
      </li>-->
          <li routerLinkActive="active" *ngIf="canViewExpenses || canCreateExpensesPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/transactions"><span class="mm-text">{{'controls.search' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" *ngIf="canReviewExpensesPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/transactions/review"><span class="mm-text">{{'controls.review' | translate}}</span></a>
          </li>
        </ul>
      </li>

      <li class="mm-dropdown  mm-dropdown-root" *ngIf="(canViewCoachings || canViewTopics || canViewQuestionHeads || canViewQuestions || canViewCoachingTransactions)">
        <a href="#"><img [src]="coashingLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'coaching.coaching' |translate}}</span></a>
        <ul>
          <li *ngIf="canViewCoachings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/coaching/coachings"><span class="mm-text">{{'coaching.coachingName' | translate}}</span></a>
          </li>
          <li *ngIf="canViewTopics" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/coaching/topics"><span class="mm-text">{{'coaching.topic' | translate}}</span></a>
          </li>
          <li *ngIf="canViewQuestionHeads" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/coaching/questions-heads"><span class="mm-text">{{'coaching.questionHead' | translate}}</span></a>
          </li>
          <li *ngIf="canViewQuestions" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/coaching/questions"><span class="mm-text">{{'coaching.question' | translate}}</span></a>
          </li>
          <li *ngIf="canViewCoachingTransactions" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/coaching/coaching-transactions"><span class="mm-text">{{'coaching.coachingTransaction' | translate}}</span></a>
          </li>

        </ul>
      </li>

      <li *ngIf="canCreateVacations || canViewVacations|| canReviewVacations" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/vacations/non-fields"><img [src]="nonFieldActivityLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Non Field Activities & Vacations' |translate}}</span></a>
      </li>

      <!--<li class="mm-dropdown  mm-dropdown-root" *ngIf="canCreateVacations || canViewVacations|| canReviewVacations">
    <a href="#"><i class="menu-icon fa fa-list"></i><span class="mm-text">{{'Non Field Activities & Vacations' |translate}}</span></a>
    <ul>
      <li routerLinkActive="active" *ngIf="canViewVacations || canCreateVacations" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/vacations/non-fields"><span class="mm-text">{{'controls.search' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="canReviewVacations" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/Vacations/review-non-fields"><span class="mm-text">{{'controls.review' | translate}}</span></a>
      </li>
    </ul>
  </li>-->
      <!--<li class="mm-dropdown  mm-dropdown-root" *ngIf="canCreateVacations || canViewVacations|| canReviewVacations">
    <a href="#"><i class="menu-icon fa fa-list"></i><span class="mm-text">{{'Vacations' |translate}}</span></a>
    <ul>
      <li routerLinkActive="active" *ngIf="canCreateVacations" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/vacations/add-vacation"><span class="mm-text">{{'controls.add' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="canViewVacations" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/vacations/vacations"><span class="mm-text">{{'controls.search' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="canReviewVacations" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/Vacations/review-vacations"><span class="mm-text">{{'controls.review' | translate}}</span></a>
      </li>
    </ul>
  </li>-->
      <li routerLinkActive="active" *ngIf="applyingVacationModule == 'True' && canViewVacationCreditsPermission" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/vacation-credit"><img [src]="vacCreditLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Vacation Credit' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="canViewContents && applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/contents"><img [src]="contentsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.contents' | translate}}</span></a>
      </li>


      <li routerLinkActive="active" *ngIf="(canViewConsents || canManageConsents) && applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/consents"><img [src]="consentsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.consents' | translate}}</span></a>
      </li>

      <li routerLinkActive="active" *ngIf="(canViewConsentTypes || canManageConsentTypes) && applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/consent-types"><img [src]="consentTypeLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.consentTypes' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="(canAddConsentForms || canViewConsentForms || canEditConsentForms)&& applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/consent-forms"><img [src]="consentFormLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.consentForms' | translate}}</span></a>
      </li>

      <li routerLinkActive="active" *ngIf="(canViewEmailTemplates || canManageEmailTemplates)  && applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/email-templates"><img [src]="emailTemplateLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.emailTemplates' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" *ngIf="(canViewEmailTransactions || canManageEmailTransactions)  && applyingCLM == 'True'" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/clm/email-campaigns"><img [src]="emailCampaignLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.emailCampaign' | translate}}</span></a>
      </li>

      <li routerLinkActive="active" *ngIf="canViewMessagesPermission" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/message"><img [src]="usrSamplesReqLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Messages' | translate}}</span></a>
      </li>

      <!--<li class="mm-dropdown mm-dropdown-root" *ngIf="canViewMessagesPermission">
    <a href="#"><img [src]="usrSamplesReqLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'Messages' |translate}}</span></a>
    <ul>
      <li *ngIf="canCreateMessagesPermission" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="message/send"><span class="mm-text">{{'Send' | translate}}</span></a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="message/receive"><span class="mm-text">{{'Receive' | translate}}</span></a>
      </li>

    </ul>
  </li>-->

      <li class="mm-dropdown mm-dropdown-root" *ngIf="canViewAllReports || canViewActualVisitsReport || canViewTotalVisitsReport || canViewCoverageReport || canViewUserHCOs || canViewPlanReport">
        <a href="#"><img [src]="reportsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.reports' |translate}}</span></a>
        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Visits Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewActualVisitsReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/actual-visits"><span class="mm-text">{{'reports.actualVisits' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewTotalVisitsReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/visits-details"><span class="mm-text">{{'reports.totalVisits' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewTotalDoubleVisitsReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/double-visits-details"><span class="mm-text">{{'Double Visits Details' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewCoverageReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/coverage"><span class="mm-text">{{'reports.coverage' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewPhVisitPerformanceReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/ph-coverage"><span class="mm-text">PH {{'reports.coverage' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewActualVisitsReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/hco-visits"><span class="mm-text">{{'reports.hcoVisits' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewDoubleVisit" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/double-visit"><span class="mm-text">{{'Double Visit' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewPlanReport " [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/plans"><span class="mm-text">{{'reports.plans' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewHcoReactionReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/hco-reaction"><span class="mm-text">{{'Hco Reaction' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewPlannedDaysReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/planned-days"><span class="mm-text">{{'Planned Days' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewFackedVisitLocationReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/faked-visit"><span class="mm-text">{{'Faked Visit Location' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewVisitScriptReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/visit-script"><span class="mm-text">{{'Visit Script' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewSpecialityClassReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/speciality-class"><span class="mm-text">{{'Speciality Class' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewProductiveCallFreqReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/productive-call-freq"><span class="mm-text">{{'Productive Call Freq' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewProductiveCallFreqPharmacyReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/productive-call-freq-pharmacy"><span class="mm-text">{{'PH Productive Call Freq' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewNonSalesKpisReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/non-sales-kpis"><span class="mm-text">{{'Non Sales KPIs' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewReturnOnInvestmentReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/return-on-investment"><span class="mm-text">{{'ROI' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Activity & Vacation Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewNonFieldActivityReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/non-field-activity"><span class="mm-text">{{'Non Field Activity' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewVacationCredeitReportPermission && applyingVacationModule == 'True' " [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/vacation-credit"><span class="mm-text">{{'Vacation Credit' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewActivityReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/activity"><span class="mm-text">{{'Activity' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewNonFieldActivityReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/vacation"><span class="mm-text">{{'Vacation' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewAbsentReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/absent"><span class="mm-text">{{'Absent' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Expenses Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewCoverageReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/expenses"><span class="mm-text">{{'reports.expenses' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Sample And Giveaway Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewSampleStockReport && applyingSampleModule == 'True'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/sample-stock"><span class="mm-text">{{'Sample Stock' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewSampleTrackerReport && applyingSampleModule == 'True'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/sample-tracker"><span class="mm-text">{{'Sample Tracker' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewGiveawayTrackerReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/giveaway-tracker"><span class="mm-text">{{'Giveaway Tracker' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
        <ul *ngIf="canViewAvgContentSlideTimeReportPermission || canViewAvgProductWithContentReportPermission || canViewDetailingTimeContentReportPermission">
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'CLM Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewAvgContentSlideTimeReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/content-slide"><span class="mm-text">{{'Average time per slide' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewAvgProductWithContentReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/product-content"><span class="mm-text">{{'Calls with E-Detailing' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewDetailingTimeContentReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/detailing-time"><span class="mm-text">{{'Product Detailing time' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>

        <!--<ul>
      <li class="mm-dropdown mm-dropdown-root">
        <a href="#"><i class="menu-icon fa fa-user-md"></i><span class="mm-text">{{'CLM Reports' |translate}}</span></a>
        <ul>
        </ul>
      </li>
    </ul>-->

        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Product Visit Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewProudctSpecialityReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/speciality-product"><span class="mm-text">{{'Product Speciality' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewProudctSpecialityClassReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/product-speciality-class"><span class="mm-text">{{'Product Class Speciality' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewProudctOrganizationTypeReport" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/product-organization-type"><span class="mm-text">{{'Product Oganization Type' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'HCOs Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewUserHCOs" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/user-hcos"><span class="mm-text">{{'My HCOs' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewHCOsLineClass" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/hcos-line-class"><span class="mm-text">{{'HCOs Line Class' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewHcoOrgTypeReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/hco-org-type"><span class="mm-text">{{'Hco Organization Type' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewHcoLinkedPharmaciesReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/hco-linked-pharmacies"><span class="mm-text">{{'Hco Linked Pharmacies' | translate}}</span></a>
              </li>
              <li routerLinkActive="active" *ngIf="canViewLinkedPharmacyStatisticsReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/linked-pharmacy-statistics"><span class="mm-text">{{'Linked Pharmacy Statistics' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>

        <ul *ngIf="canViewCoachingDetailsReportPermission || canViewCoachingRateReportPermission">
          <li class="mm-dropdown mm-dropdown-root">
            <a href="#"><img [src]="logo" class="img-icon" /><span class="mm-text">{{'Coaching Reports' |translate}}</span></a>
            <ul>
              <li routerLinkActive="active" *ngIf="canViewCoachingRateReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/coatching-rate"><span class="mm-text">{{'Coaching Rate' | translate}}</span></a>
              </li>

              <li routerLinkActive="active" *ngIf="canViewCoachingDetailsReportPermission" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/reports/coaching-rate-details"><span class="mm-text">{{'Coaching Details' | translate}}</span></a>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li routerLinkActive="active" *ngIf="canViewBusinessUnitStructure" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/business-unit-structure"><span class="mm-text">{{'Business Unit Structure' | translate}}</span></a>
          </li>

          <li routerLinkActive="active" *ngIf="canViewUpToDateStatusReportPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/uptodate-status"><span class="mm-text">{{'Up To Date Status' | translate}}</span></a>
          </li>

          <li routerLinkActive="active" *ngIf="canViewSyncLogErrorsReportPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/sync-log-errors"><span class="mm-text">{{'Sync Errors' | translate}}</span></a>
          </li>

          <li routerLinkActive="active" *ngIf="canViewBrickStructureReportPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/brick-structure"><span class="mm-text">{{'Bricks Structure' | translate}}</span></a>
          </li>

          <li routerLinkActive="active" *ngIf="canViewManagerPerformanceReportPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/manager-performance"><span class="mm-text">{{'Manager Performance' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" *ngIf="canViewBusinessUnitBricksHistoryReportPermission" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/reports/business-unit-bricks"><span class="mm-text">{{'Business Unit Bricks History' | translate}}</span></a>
          </li>

        </ul>
      </li>

      <li class="mm-dropdown mm-dropdown-root" *ngIf="(canCreateIncentive || canEditIncentive || canManageIncentive) && applyingIncentivesModule == 'True'">
        <a href="#"><img [src]="reportsLogo" class="img-icon" />&nbsp;&nbsp;<span class="mm-text">{{'mainMenu.incentives' |translate}}</span></a>
        <ul>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/rule"><span class="mm-text">{{'Incentive Rule Settings' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/incentive"><span class="mm-text">{{'Incentive' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/product-weight"><span class="mm-text">{{'Product Weight' | translate}}</span></a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/incentive-scheme"><span class="mm-text">{{'CRM Incentive Scheme' | translate}}</span></a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
