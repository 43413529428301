import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LookupsRoutingModule } from "./lookups-routing.module";
import { JobsComponent } from './jobs/jobs.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { PaginationModule } from "ngx-bootstrap";
import { SaveJobComponent } from './jobs/save-job/save-job.component';
import { hcpClassComponent } from "./hcpClass/hcpClass.component";
import { TranslateLanguageLoader } from "../services/app-translation.service";
//import { SharedModule } from "../shared/shared.module";
import { TopSharedModule } from "../top-shared/top-shared.module";
import { AddhcpClassComponent } from "./hcpClass/add-hcpClass/add-hcpClass.component";
import { ActivityTypeComponent } from './activity-type/activity-type.component';
import { AddActivityTypeComponent } from './activity-type/add-activity-type/add-activity-type.component';
import { VacationTypeComponent } from './vacation-type/vacation-type.component';
import { VacationTypeAddComponent } from './vacation-type/vacation-type-add/vacation-type-add.component';
import { CoachingComponent } from './coaching/coaching.component';
import { AddCoachingComponent } from './coaching/add-coaching/add-coaching.component';
import { SharedModule } from "../shared-lazy/shared/shared.module";
import { GiveawayItemsComponent } from './giveaway-items/giveaway-items.component';
import { SaveGiveawayItemComponent } from './giveaway-items/save-giveaway-item/save-giveaway-item.component';
import { RepresentationComponent } from './representation/representation.component';
import { SaveRepresentationsComponent } from './representation/save-representations/save-representations.component';
import { SalesFileComponent } from './sales-file/sales-file.component';
import { ProvisionVacationTypeComponent } from './vacation-type/provision-vacation-type/provision-vacation-type.component';
import { ModalModule } from "ngx-bootstrap";
import { ItemFormatComponent } from './item-format/item-format.component';
import { AddItemFormatComponent } from './item-format/add-item-format/add-item-format.component';
import { ReactionComponent } from './reaction/reaction.component';
import { AddReactionComponent } from './reaction/add-reaction/add-reaction.component';
import { ItemBrandComponent } from './item-brand/item-brand.component';
import { AddItemBrandComponent } from './item-brand/add-item-brand/add-item-brand.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AddFeedbackComponent } from './feedback/add-feedback/add-feedback.component';
import { ActivityGroupComponent } from './activity-group/activity-group.component';
import { AddActivityGroupComponent } from './activity-group/add-activity-group/add-activity-group.component';
import { ActivityCostCenterComponent } from './activity-cost-center/activity-cost-center.component';
import { AddActivityCostCenterComponent } from './activity-cost-center/add-activity-cost-center/add-activity-cost-center.component';
import { ActivityCostTypeComponent } from './activity-cost-type/activity-cost-type.component';
import { AddActivityCostTypeComponent } from './activity-cost-type/add-activity-cost-type/add-activity-cost-type.component';
import { ProvisionCostCenterComponent } from './activity-cost-center/provision-cost-center/provision-cost-center.component';
import { VendorComponent } from './vendor/vendor.component';
import { AddVendorComponent } from './vendor/add-vendor/add-vendor.component';
import { BusinessUnitSharedBricksComponent } from './business-unit-shared-bricks/business-unit-shared-bricks.component';
import { SaveBusinessUnitSharedBricksComponent } from './business-unit-shared-bricks/save-business-unit-shared-bricks/save-business-unit-shared-bricks.component';
import { ItemRangeComponent } from './item-range/item-range.component';
import { SaveItemRangeComponent } from './item-range/save-item-range/save-item-range.component';
import { BranchesComponent } from './branches/branches.component';
import { SaveBranchComponent } from './branches/save-branch/save-branch.component';

@NgModule({
  declarations: [
    AddhcpClassComponent ,
    JobsComponent,
    SaveJobComponent,
    hcpClassComponent,
    ActivityTypeComponent,
    AddActivityTypeComponent,
    VacationTypeComponent,
    VacationTypeAddComponent,
    CoachingComponent,
    AddCoachingComponent,
    GiveawayItemsComponent,
    SaveGiveawayItemComponent,
    RepresentationComponent,
    SaveRepresentationsComponent,
    SalesFileComponent,
    ProvisionVacationTypeComponent,
    ItemFormatComponent,
    AddItemFormatComponent,
    ReactionComponent,
    AddReactionComponent,
    ItemBrandComponent,
    AddItemBrandComponent,
    FeedbackComponent,
    AddFeedbackComponent,
    ActivityGroupComponent,
    AddActivityGroupComponent,
    ActivityCostCenterComponent,
    AddActivityCostCenterComponent,
    ActivityCostTypeComponent,
    AddActivityCostTypeComponent,
    ProvisionCostCenterComponent,
    VendorComponent,
    AddVendorComponent,
    BusinessUnitSharedBricksComponent,
    SaveBusinessUnitSharedBricksComponent,
    ItemRangeComponent,
    SaveItemRangeComponent,
    BranchesComponent,
    SaveBranchComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    FormsModule,
    ModalModule.forRoot(),
    CommonModule,
    LookupsRoutingModule,
    SharedModule,
    TopSharedModule,
    PaginationModule.forRoot() 
  ]
})
export class LookupsModule { }
