import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Vendor } from '../../models/lookups/vendor.model';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  save(vendor: Vendor) {
    return this.http.post(this.configurations.apiAddress + "/api/Vendor/save", vendor);
  }

  search(searchText: string, date: Date = undefined) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set('date', date == undefined ? "" : date.toDateString())
    return this.http.get(this.configurations.apiAddress + '/api/Vendor/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/Vendor/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/Vendor/getById/' + id);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/Vendor/getAll/');
  }

}
