import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { ItemRange } from '../../models/lookups/item-range.model';

@Injectable({
  providedIn: 'root'
})
export class ItemRangeService {
  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }
  save(itemRange: ItemRange) {
    return this.http.post(this.configurations.apiAddress + "/api/ItemRange/save", itemRange);
  }

  search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/ItemRange/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/ItemRange/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ItemRange/getById/' + id);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/ItemRange/getAll/');
  }

}
