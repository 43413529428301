<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'settings.header.holiday' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'settings.header.holiday' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.holiday' | translate}} {{'Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="name"
                 [(ngModel)]="searchText"
                 placeholder="{{'Name' |translate}}"
                 #name="ngModel"
                 autocomplete="off"
                 class="form-control" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="searchholidays()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="new()">
          <span class="margin-right-7 create-new-span">Create New Holiday</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-holiday *ngIf="addNew" (closeEvent)="closeAdding($event)" [holidayid]="selectedHolidayId "></crm-add-holiday>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.holiday' | translate}} {{'Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="holidays" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize"  [totalRecords]="totalItems" [lazy]="true" (onLazyLoad)="pageChanged($event)">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-holiday>
              <tr *ngIf="holidays.length>0 && displayMode==2">
                <!--<td>
                  <strong>{{holiday.id}}</strong>
                </td>-->
                <td>{{ holiday.name }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(holiday.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDelete(holiday.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="holidays.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteholiday()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
