import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemRange } from '../../../models/lookups/item-range.model';
import { DisplayModes } from '../../../models/enums';
import { ItemBrand } from '../../../models/lookups/item-brand.model';
import { ItemRangeService } from '../item-range.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ItemBrandService } from '../../item-brand/item-brand.service';

@Component({
  selector: 'crm-save-item-range',
  templateUrl: './save-item-range.component.html',
  styleUrls: ['./save-item-range.component.css']
})
export class SaveItemRangeComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  itemRange: ItemRange = new ItemRange();
  itemBrandes: ItemBrand[] = [];
  itemBrand: ItemBrand;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemRangeId: number;
  constructor(private readonly itemRangeService: ItemRangeService,
    private readonly route: ActivatedRoute, private readonly itemBrandService: ItemBrandService,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    this.getAllItemBrands();
    if (this.itemRangeId != undefined)
      this.getItemRange(this.itemRangeId);
  }

  getAllItemBrands() {
    this.itemBrandService.getAll().subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.itemBrandes = responce as ItemBrand[];
      if (this.itemRange.itemBrandId && this.itemBrandes && this.itemBrandes.length > 0) {
        this.itemRange.itemBrand = this.itemBrandes.find(x => x.id == this.itemRange.itemBrandId);
      }
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item brands');
        console.log(err);
      });
  }
  getItemRange(itemRangeId) {
    this.displayMode = DisplayModes.Loading;
    this.itemRangeService.getById(itemRangeId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.itemRange = responce as ItemRange;
      if (this.itemRange.itemBrandId && this.itemBrandes && this.itemBrandes.length > 0) {
        this.itemRange.itemBrand = this.itemBrandes.find(x => x.id == this.itemRange.itemBrandId);
      }
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item range');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      if (this.itemRange.itemBrand !== null && this.itemRange.itemBrand !== undefined)
        this.itemRange.itemBrandId = this.itemRange.itemBrand.id;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.itemRangeService.save(this.itemRange).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
